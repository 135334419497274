import React from "react";
import styled from "styled-components";

export const StyledLogIn = styled.h5`
  background-color: white;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 3px 3px;
`;

export const Login = (): React.ReactElement => {
  return (
    <div>
      <StyledLogIn>Log In</StyledLogIn>
    </div>
  );
};

export default Login;
