import React from "react";
import styled from "styled-components";
import { AppRouter } from "./AppRouter";

export const StyledBody = styled.div`
  // margin-top: 100px;

  // height: 100%;
  align-items: center;
  background: #2c2c2c;
  top: 100px;
  bottom: 200px;
  position: absolute;
  width: 100%;
`;

export const Body = (): React.ReactElement => {
  return (
    <StyledBody>
      <AppRouter></AppRouter>
    </StyledBody>
  );
};
