import React from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";

// div {
//   height: 150px;
//   width: 150px;
//   background: #2c2c2c;
//   border-radius: 15px;
//   margin: 20px;
//   transition: all ease 0.2s;
//   border: 1px solid #333;
// }
// .box1 {
//   box-shadow: 0px 5px 10px 0px rgba(0,255,255,0.7);
// }
// .box2{
//   box-shadow: inset 0px 5px 10px 0px rgba(0,255,255,0.7);
// }
// .box1:hover{
//   transform: translateY(-5px);
//   box-shadow: 0px 10px 20px 2px rgba(0,255,255,0.7);
// }
// .box2:hover{
//   transform: translateY(5px);
//   box-shadow: inset 0px 10px 20px 2px rgba(0,255,255,0.7);
// }

export const MenuLink = styled(Link)`
  height: 40px;
  width: 100px;
  color: white;
  text-decoration: none;
  line-height: 40px;
  // justify-content: center;
  // align-items: center;
  background: #2c2c2c;
  border-radius: 15px;
  margin: 20px;
  transition: all ease 0.2s;
  border: 1px solid #333;
  box-shadow: 0px 3px 6px 0px rgba(0, 255, 255, 0.7);
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 255, 255, 0.7);
  }
`;
export const Hamburger = styled(FaBars)`
  display: none;
  color: #ffffff;
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.9rem;
    top: 0;
    right: 0;
    position: absolute;
    cursor: pointer;
    transform: translate(-100%, 75%);
  }
`;
export const Menu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -25px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Navbar = (): React.ReactElement => {
  return (
    <>
      <Hamburger />
      <Menu>
        <MenuLink to="/home">Home</MenuLink>
        <MenuLink to="/about">About</MenuLink>
        <MenuLink to="/products">Products</MenuLink>
        <MenuLink to="/blog">Blog</MenuLink>
      </Menu>
    </>
  );
};
