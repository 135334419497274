import React from "react";
import styled from "styled-components";

export const StyledText = styled.h5`
  color: white;
`;

export const About = (): React.ReactElement => {
  return (
    <div>
      <StyledText>
        Welcome to Frefab IT About page. This site is under construction
      </StyledText>
    </div>
  );
};
