import styled from 'styled-components'

export const StyledContainer = styled.nav` 
  top: 0;
  display: flex;
  z-index: 14;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  width: 100%;
  position: fixed;  
  background: #222;
  box-shadow: 0px 1px 2px rgba(0,255,255,0.5), 
               0px 1px 16px rgba(0,255,255,0.5)

  // box-shadow: 0px 1px 2px rgba(0,255,255,0.5), 
  //             0px 2px 4px rgba(0,255,255,0.5), 
  //             0px 4px 8px rgba(0,255,255,0.5), 
  //             0px 8px 16px rgba(0,255,255,0.5);
}
`;

export const StyledLeftColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledRightColumn = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export default StyledContainer
