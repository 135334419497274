import "./App.css";
import React, { FC } from "react";
import { Header } from "features/header/components/Header/Header";
import { Body } from "features/header/components/Body/Body";
import { Footer } from "features/header/components/Footer/Footer";

const App: FC = () => {
  return (
    <div className="App">
      <Header></Header>
      <Body></Body>
      <Footer></Footer>
    </div>
  );
};

export default App;
