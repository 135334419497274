import React from "react";
import styled from "styled-components";

// const styledTitle = {
//   color: "transparent",
//   backgroundColor: "#565656",
//   fontSize: "2rem",
//   fontWeight: "bold",
//   padding: "0 0 0 30px",
// };

export const StyledTitle = styled.h1`
  background-color: white;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 3px 3px;
`;

export const Title = (): React.ReactElement => {
  return (
    <div>
      <StyledTitle>Welcome to Frefab IT</StyledTitle>
    </div>
  );
};

export default Title;

// export const StyledTitle = styled.div`
//   align-items: center;
//   width: 100%;
//   height: 200px;
//   position: absolute;
//   bottom: 0;
//   background: #222;
//   box-shadow: 0px 1px 2px rgba(0, 255, 255, 0.5),
//     0px 2px 4px rgba(0, 255, 255, 0.5), 0px 4px 8px rgba(0, 255, 255, 0.5),
//     0px 8px 16px rgba(0, 255, 255, 0.5);
// `;

// export const Title = (): React.ReactElement => {
//   return <StyledTitle></StyledTitle>;
// };
