import React from "react";
import styled from "styled-components";

export const StyledFooter = styled.div`
  align-items: center;
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  background: #222;
  box-shadow: 0px 1px 2px rgba(0, 255, 255, 0.5),
    0px 2px 4px rgba(0, 255, 255, 0.5), 0px 4px 8px rgba(0, 255, 255, 0.5),
    0px 8px 16px rgba(0, 255, 255, 0.5);
`;

export const Footer = (): React.ReactElement => {
  return <StyledFooter></StyledFooter>;
};
