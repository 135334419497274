import React from "react";
import StyledContainer, {
  StyledLeftColumn,
  StyledRightColumn,
} from "./Header.styles";
import Login from "./Login/Login";
import { Navbar } from "./Navbar/Navbar";
import Title from "./Title";

export const Header = (): React.ReactElement => {
  return (
    <div>
      <StyledContainer id="ff-header-page-id" className="ff-header-page">
        <StyledLeftColumn>
          <Title />
        </StyledLeftColumn>
        <Navbar></Navbar>
        <StyledRightColumn>
          <Login></Login>
        </StyledRightColumn>
      </StyledContainer>
    </div>
  );
};
